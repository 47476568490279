@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Rubik", sans-serif;
  color: #222;
  background-color: #f1f5f8;
}

.container {
  display: flex;
  flex-direction: row;
}

select,
input {
  -webkit-appearance: none;
  margin-right: 0.4rem;
}

.title {
  font-size: 2rem;
  /* text-decoration-color: #3066BE; */
}

.card {
  flex: auto;
  padding: 2rem;
  margin: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  border-radius: 0.5rem;
  box-shadow: 4px 8px 15px #888888;
  text-align: center;
  cursor: pointer;
}

.active {
  background-color: #3066be;
  color: #fff;
}

.inactive {
  background-color: #fff;
  color: #3066be;
}

.error {
  background: var(--clr-primary-10);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.error h1 {
  font-size: 10rem;
}

.error h3 {
  text-transform: none;
  margin-bottom: 2rem;
}

.page-100 {
  min-height: calc(100vh - 10rem);
  padding: 5rem 0;
}

.page {
  min-height: calc(100vh - (20vh - 10rem));
}

.btn-large {
  text-decoration: none;
  text-align: center;
  padding: 0.8rem 1.1rem;
  border-radius: 5px;
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.section {
  padding: 5rem 0;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

.form-center {
  text-align: center;
}

.text-center {
  text-align: center;
}

table {
  width: 100%;
}

table thead th {
  border-bottom: 1px solid #000;
  text-align: left;
  font-size: 1.1rem;
}

table tbody td {
  border-bottom: 1px solid #e6e8e6;
  padding: 0.4rem;
}

table tbody tr:hover {
  background-color: #dfe2df;
}

.form-control {
  margin-bottom: 1.25rem;
}

.search-input {
  padding: 0.6rem;
  background: var(--clr-grey-10);
  border-radius: var(--radius);
  /* border-color: transparent; */
  border-color: #888888;
  letter-spacing: var(--spacing);
  min-width: 150px;
  font-size: 0.9rem;
}

.display-input {
  padding: 0.5rem;
  background: var(--clr-grey-10);
  border-radius: var(--radius);
  border-color: transparent;
  border-color: #888888;
  letter-spacing: var(--spacing);
  width: 100%;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.search-input::-webkit-input-placeholder {
  text-transform: capitalize;
}

.search-input::placeholder {
  text-transform: capitalize;
}

.btn-small {
  display: inline-block;
  margin: 0.25em 0 0 0.25em;
  padding: 0.45rem 0;
  text-transform: capitalize;
  border: none;
  border-bottom: 1px solid transparent;
  letter-spacing: var(--spacing);
  color: var(--clr-grey-5);
  cursor: pointer;
  font-size: 0.9rem;
  min-width: 100px;
}

.hot {
  background: #3066be;
  color: #fff;
  text-decoration: none;
  text-align: center;
}

.cold {
  background: #d4d8d4;
  color: #1d201d;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
}

.form-center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
}

.button-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr;
  margin-top: 1rem;
}

label {
  margin-bottom: 10px;
}

/* Modern HTML form CSS */

.mod-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mod-label {
  display: block;
  margin-bottom: 5px;
  color: #888;
}

.form-group {
  margin-bottom: 20px;
  margin-right: 10px;
}

.mod-input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #f7f7f7;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  transition: background-color 0.2s ease;
  font-size: 0.9rem;
}

.mod-input:focus {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mod-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #3f51b5;
  color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.mod-btn:focus {
  background-color: #2c3e50;
}

.mod-select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #f7f7f7;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  transition: background-color 0.2s ease;
  -webkit-appearance: none;
  font-size: 0.9rem;
}

.mod-select:focus {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mod-select option:first-child {
  color: #888;
}

.mod-select:invalid {
  color: #888;
}

.mod-select option[value=""] {
  display: none;
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #3f51b5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.logo a {
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-left: 20px;
  color: #fff;
}

.nav-links li a {
  font-size: 18px;
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #f2f2f2;
}

/* Container for the KPI cards */
.kpi-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}

/* CSS for the individual KPI card */
.kpi-card {
  flex: 1 1;
  max-width: calc(33.333% - 20px); /* Adjust as needed for spacing */
  padding: 20px;
  border-radius: 5px;
  margin: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom, #3498db, #1abc9c);
  color: white;
  text-align: center;
}

/* CSS for the KPI title */
.kpi-title {
  font-size: 18px;
  font-weight: bold;
}

/* CSS for the KPI value */
.kpi-value {
  font-size: 24px;
  margin-top: 10px;
}

/* CSS for the KPI description */
.kpi-description {
  font-size: 14px;
}

/* Responsive media query for smaller screens */
@media (max-width: 768px) {
  .kpi-card {
    max-width: calc(50% - 20px); /* Adjust for spacing on smaller screens */
  }
}

